<template>
  <div class="pay">
    <div class="pay-info">
      <div class="info">
        <div class="title">商品订单：</div>
        <div class="label">{{ orderId }}</div>
      </div>
      <div class="info">
        <div class="title">商品名称：</div>
        <div class="label">{{ `${productName} ${skuName} *${buyNumber}` }}</div>
      </div>
      <div class="info">
        <div class="title">绑定邮箱：</div>
        <div class="label">{{ email }}</div>
      </div>
      <div class="info">
        <div class="title">支付金额：</div>
        <div class="label">
          ￥ <span style="color: #69D9BA;">{{ price*100 }}</span>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div id="qrcode"></div>
    <div class="prompt">请打开手机微信，扫一扫完成支付</div>
    <Toast
      :showToast="showToast"
      :message="toastMsg"
      @disappear="showToast = false"
    ></Toast>
    <Loading :display="showLoading"></Loading>
  </div>
</template>
<script>
/* eslint-disable */
import QRCode from "qrcodejs2"
import Toast from "@/components/Toast"
import Loading from "@/components/Loading"
import { getOrderInfo } from "@/api/order"
import { reportError } from "@/utils/log"

export default {
  name: "PayPC",
  data() {
    return {
      toastMsg: "",
      showLoading: false,
      showToast: false,
      skuName: "",
      price: "",
      productName: "",
      buyNumber: 1,
      orderId: "",
    }
  },
  components: {
    Toast,
    Loading,
    QRCode,
  },
  methods: {
    qrcode(url) {
      let qrcode = new QRCode("qrcode", {
        text: url,
      })
    },
    async getOrderInfo() {
      let res = await getOrderInfo({
        orderId: this.orderId,
      })
      if (res.data.code == 0) {
        if (!this.productName) {
          this.productName = res.data.order.meta.productName
          this.skuName = res.data.order.meta.skuName
          this.buyNumber = res.data.order.meta.buyNumber
          this.price = Number(res.data.order.meta.price / 100).toFixed(2)
          this.email = res.data.order.email
        }
        if (res.data.order.status == 3) {
          this.$router.push(
            "/eshop/pay-success?orderId=" +
              this.orderId
          )
        } else {
          setTimeout(() => {
            this.getOrderInfo()
          }, 3000)
        }
      }
    },
  },
  mounted() {
    if (this.$route.query.qrUrl) {
      this.qrcode(this.$route.query.qrUrl)
      this.orderId = this.$route.query.orderId
      this.getOrderInfo()
    }
  },
}
</script>

<style lang="less" scoped>
.pay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .pay-info {
    width: 7.5rem;
    display: flex;
    overflow-x: hidden;
    flex-direction: column;
    justify-content: center;
    .info {
      margin-left: 0.24rem;
      display: flex;
      height: 0.6rem;
      width: 7.02rem;
      align-items: center;
      .title {
        font-size: 0.28rem;
        color: #565656;
        font-weight: bold;
      }
      .label {
        font-size: 0.28rem;
        color: #565656;
      }
    }
  }
  .divider {
    width: 100%;
    height: 0.02rem;
    background: #f6f7f8;
  }
  #qrcode {
    margin-top: 0.36rem;
    margin-bottom: 0.24rem;
  }
  .prompt {
    width: 4.5rem;
    height: 0.7rem;
    text-align: center;
    line-height: 0.7rem;
    font-size: 0.28rem;
    border-radius: 0.04rem;
    background: #69d9ba;
    color: white;
  }
}
</style>
